import { useState } from "react"

function LazyYouTube({
  customerName,
  videoId,
}: {
  customerName: string
  videoId: string
}) {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
      className="w-full aspect-video md:h-[380px] rounded"
      title={`${customerName || "Customer"}'s testimonial`}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
      key={videoId}
    />
  )
}

export default LazyYouTube
