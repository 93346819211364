import React, { Fragment } from "react"

import Image from "next/legacy/image"
import PropTypes from "prop-types"

const StarRating = ({ rating, star, alt = "star", wrapperClass }) => {
  return (
    <div className={wrapperClass + " flex"}>
      {Array(rating)
        .fill(
          <Image
            src={star.src}
            alt={alt}
            height={star.height}
            width={star.width}
            loading="lazy"
          />,
        )
        .map((star, id) => (
          <Fragment key={id}>{star}</Fragment>
        ))}
    </div>
  )
}
StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  star: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    src: PropTypes.string,
  }),
  alt: PropTypes.string,
  wrapperClass: PropTypes.string,
}

export default StarRating
