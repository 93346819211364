import { FC } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import LazyYouTube from "./LazyYoutube"

export interface TestimonialItem {
  customerName: string
  embedUrl: string
  customerReview: string
  customerRating: number
  shipmentInfo: string
}

export interface TestimonialsProps {
  blok: {
    items: TestimonialItem[]
    title: string
    subtitle: string
  }
}

const Testimonials: FC<TestimonialsProps> = ({ blok }) => {
  const { title, subtitle, items } = blok

  const hasContent = (item: TestimonialItem) =>
    item.customerReview.trim() !== "" ||
    item.shipmentInfo.trim() !== "" ||
    item.customerName.trim() !== ""

  const TestimonialCard = ({
    item,
    index,
  }: {
    item: TestimonialItem
    index: number
  }) => (
    <div
      key={item.embedUrl + index}
      className="bg-white overflow-hidden flex flex-col font-inter"
    >
      <div className="aspect-video md:h-[380px] shadow-sm rounded-lg">
        <LazyYouTube
          videoId={item?.embedUrl}
          customerName={item?.customerName}
        />
      </div>
      <div className="p-4 flex flex-col flex-grow font-inter">
        {item.customerName && (
          <h3 className="text-lg font-semibold">{item.customerName}</h3>
        )}
        {item.shipmentInfo && (
          <p className="text-sm text-gray-500">{item.shipmentInfo}</p>
        )}
        {hasContent(item) && item.customerRating > 0 && (
          <div className="flex">
            {Array.from({ length: 5 }).map((_, starIndex) => (
              <span
                key={starIndex}
                className={`text-yellow-500 ${
                  starIndex < item.customerRating
                    ? "fill-current"
                    : "text-gray-300"
                }`}
              >
                ★
              </span>
            ))}
          </div>
        )}
        {item.customerReview && (
          <p className="mt-1 text-gray-700">{item.customerReview}</p>
        )}
      </div>
    </div>
  )

  const onSlideChange = (swiper: any) => {
    swiper.slides.forEach((slide: HTMLElement, index: number) => {
      if (index !== swiper.activeIndex) {
        const iframe = slide.querySelector("iframe")
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            JSON.stringify({
              event: "command",
              func: "pauseVideo",
              args: [],
            }),
            "*",
          )
        }
      }
    })
  }

  const renderContent = () => {
    if (items.length === 1) {
      return (
        <div className="max-w-3xl mx-auto">
          <TestimonialCard item={items[0]} index={0} />
        </div>
      )
    }

    if (items.length <= 3) {
      return (
        <>
          <div
            className={`max-w-6xl mx-auto hidden md:grid md:grid-cols-${items.length} gap-6`}
          >
            {items.map((item, index) => (
              <TestimonialCard
                key={item.embedUrl + index}
                item={item}
                index={index}
              />
            ))}
          </div>
          <div className="block md:hidden">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={24}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              className="mySwiper"
              onSlideChange={onSlideChange}
              onSlideChangeTransitionStart={onSlideChange}
              onSlideChangeTransitionEnd={onSlideChange}
            >
              {items.map((item, index) => (
                <SwiperSlide key={item.embedUrl + index}>
                  <div className="px-2">
                    <TestimonialCard item={item} index={index} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )
    }

    return (
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={24}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          768: {
            slidesPerView: items.length > 4 ? 4 : items.length,
          },
        }}
        className="mySwiper"
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.embedUrl + index}>
            <div className="px-2">
              <TestimonialCard item={item} index={index} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  return (
    <section className="py-12 bg-white mt-13">
      <div className="container mx-auto px-4 mt-5 md:mt-12 pt-5 md:pt-10">
        <h2 className="text-[40px] leading-[54px] text-center mb-8 font-merriweather">
          {title}
        </h2>
        <p className="text-2xl leading-[36px] text-center mb-12 pb-2 md:pt-10 font-inter">
          {subtitle}
        </p>
        {renderContent()}
      </div>
    </section>
  )
}

export default Testimonials
