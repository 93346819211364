import Image from "next/image"
import { FC } from "react"
import classNames from "classnames"
import { readImage } from "../../app/utils/read-image"
import { BlokImage } from "../../storyblok"
import PageNav, { NavigationType } from "../../storyblok/sections/PageNav"
import Badge from "../../assets/images/all-driver-reviews/moved-badge.svg"
import { Anchor } from "../../app/components/anchor"

export interface IPartnersHero {
  blok: {
    tagline: string
    title: string
    description: string
    buttonLink: string
    buttonLabel: string
    bgImage?: BlokImage
    bgSmImage?: BlokImage
    navItems?: NavigationType["navItems"]
    categories?: NavigationType["categories"]
    isNavHidden?: boolean
    ctaRoute?: string
    hasNavigation?: boolean
    showBadge?: boolean
  }
}
const PartnersHero: FC<IPartnersHero> = (props) => {
  const {
    tagline,
    title,
    description,
    buttonLabel,
    buttonLink,
    bgSmImage,
    bgImage,
    ctaRoute,
    navItems = [],
    categories = [],
    isNavHidden,
    hasNavigation,
    showBadge = false,
  } = props.blok
  return (
    <>
      {hasNavigation && (
        <PageNav
          noBlok
          route={ctaRoute || buttonLink}
          buttonLabel={buttonLabel}
          navigationData={{ navItems, categories, isNavHidden }}
          position=""
        />
      )}
      <section
        id="partnership-hero"
        className={classNames("relative md:h-[685px]")}
      >
        <div className="absolute h-[560px] inset-0 z-1 md:h-full">
          {bgImage && (
            <Image
              className="hidden md:block"
              src={readImage(bgImage)}
              alt={bgImage.alt || "hero image"}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          )}
          {bgSmImage && (
            <Image
              className="md:hidden"
              src={readImage(bgSmImage ?? bgImage)}
              alt={bgSmImage.alt || "hero image"}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          )}
          {showBadge && (
            <Image
              className="w-[96px] h-[96px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] absolute bottom-4 right-4 sm:bottom-10 md:bottom-[160px] md:right-10 z-10"
              src={Badge}
              alt={"100k pets transported"}
              width={120}
              height={120}
            />
          )}
        </div>

        <div className="absolute h-[560px] inset-0 bg-white bg-opacity-75 md:h-full"></div>

        <div className="h-[560px] md:h-full relative xl:max-w-7xl mx-auto px-6 lg:px-8 py-8">
          <div className="text-black font-merriweather text-left">
            <p className="text-md font-light italic md:mt-10 lg:mt-20 mb-0">
              {tagline}
            </p>
            <h1 className="text-[26px] leading-[45px] sm:text-[42px] sm:leading-[72px] font-bold mt-1 md:mt-4 mb-6">
              {title}
            </h1>
            <p className="text-lg max-w-4xl font-inter">{description}</p>
          </div>

          <Anchor
            href={buttonLink}
            className="inline-block mt-5 md:w-auto px-10 md:px-12 rounded-md py-3 md:py-4 text-center bg-blue7 hover:bg-blue-700 text-white hover:text-white text-md md:text-lg hover:no-underline"
          >
            {buttonLabel}
          </Anchor>
        </div>
      </section>
      <section className="lg:mb-10" />
    </>
  )
}
export default PartnersHero
