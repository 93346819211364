import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import Image from "next/image"
import { Anchor } from "../../app/components/anchor"
import { readImage } from "../../app/utils/read-image"

export interface IArticlesSection extends SbBlokData {
  header: string
  markdown: string
  articles: {
    image: BlokImage
    title: string
    link: string
  }[]
}

export default function ArticlesSection({ blok }: { blok: IArticlesSection }) {
  const { header, markdown, articles } = blok

  return (
    <section className="w-full page-padding ">
      {header && (
        <h3 className="font-montserrat text-center text-4xl text-blue7 mb-4">
          {header}
        </h3>
      )}
      {markdown && (
        <Markdown className="text-center mb-10">{markdown}</Markdown>
      )}
      <aside className="w-full grid auto-rows-auto grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10">
        {articles &&
          articles.map((article, i) => (
            <Anchor
              key={`/articlesSection/${i}`}
              href={article.link}
              className="w-full max-w-xs sm:max-w-none sm:w-72 h-fit sm:h-full mx-auto bg-gray3 rounded-2xl hover:no-underline hover:text-blue5"
            >
              <Image
                className="w-full object-contain"
                src={readImage(article.image)}
                alt={article.image.alt}
                height={200}
                width={288}
              />
              <p className="text-xl font-medium text-black m-0 px-6 pt-3 pb-6">
                {article.title}
              </p>
            </Anchor>
          ))}
      </aside>
    </section>
  )
}
