import { createUseStyles } from "react-jss"
import { useRouter } from "next/router"
import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { Anchor } from "../../../app/components/anchor"
import Image from "next/image"
import classNames from "classnames"
import Markdown from "../../../components/content/Markdown"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import PrimaryCard from "../../../components/cards/PrimaryCard"
import Breadcrumb, { BreadcrumbItem } from "../../../components/breadcrumbs"
import useBusinessHours from "../../../util/useBusinessHours"
import { mediaQueries } from "../../../styles/breakpoints"

import PageNav, { NavigationType } from "../PageNav"
import { BlokImage } from "../.."
import { readImage } from "../../../app/utils/read-image"

const useStyles = createUseStyles(
  {
    container: {
      maxWidth: "1400px",
      margin: "auto",
    },
    wrapper: {
      height: "271px",
      width: "100%",
      [mediaQueries.tablet]: {
        height: "480px",
        width: "100%",
      },
      [mediaQueries.desktop]: {
        height: "420px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      [mediaQueries.largeDesktop]: {
        height: "326px",
        width: "596px",
      },
    },
    wrapperEmbed: {
      height: "271px",
      width: "100%",
      position: "relative",
      [mediaQueries.tablet]: {
        height: "360px",
        width: "100%",
      },
      [mediaQueries.desktop]: {
        height: "380px",
        width: "100%",
      },
      [mediaQueries.largeDesktop]: {
        height: "420px",
        width: "580px",
      },
    },
    wrapperImage: {
      filter: "blur(5px)",
      height: "100%",
      objectFit: "contain",
      width: "100%",
    },
    content: {
      "& p": {
        lineHeight: "28px",
      },
    },
    wrapperResponsive: {
      margin: "10px 0 16px",
      [mediaQueries.desktop]: {
        display: "none",
      },
    },
  },
  { link: true },
)
interface IPillarsHeader extends SbBlokData {
  heading: string
  content: string
  image: BlokImage
  stats: Array<
    {
      icon: BlokImage
      markdown: string
      className?: string
    } & SbBlokData
  >
  buttonLabel: string
  path?: string
  showButton?: boolean
  showHours?: boolean
  embedId?: string
  image1: BlokImage
  image2: BlokImage
  hasHeroVideo?: boolean
  navItems: NavigationType["navItems"]
  categories: NavigationType["categories"]
  isNavHidden?: NavigationType["isNavHidden"]
  mobileSubNavTitle?: NavigationType["mobileSubNavTitle"]
  navbuttonLabel?: string
  externalPath?: string
  items?: BreadcrumbItem[]
}

export default function PillarsHeader(props: { blok: IPillarsHeader }) {
  const router = useRouter()

  const {
    heading,
    content,
    image,
    stats,
    buttonLabel,
    path = "shipments/new/animals",
    showButton,
    showHours,
    embedId = "5n4hsts7lg",
    image1,
    image2,
    hasHeroVideo = true,
    navItems,
    categories,
    isNavHidden,
    navbuttonLabel,
    mobileSubNavTitle,
    externalPath,
    items,
  } = props.blok

  const param = router?.query?.kw || ""
  const { isInBusinessHours } = useBusinessHours()
  const classes = useStyles()
  let className

  if (stats.length <= 3) {
    className = "flex flex-col sm:flex-row"
  } else {
    className = "grid grid-cols-1 md:grid-cols-2"
  }

  const handleCTAPath = () => {
    if (externalPath) {
      return router.push(externalPath)
    }

    return router.push(`/${path}`)
  }

  return (
    <>
      <PageNav
        noBlok
        buttonLabel={navbuttonLabel ? navbuttonLabel : buttonLabel}
        route={path}
        navigationData={{
          navItems,
          categories,
          isNavHidden,
          mobileSubNavTitle,
        }}
      />
      <section
        id="pet-transportation-header"
        className={classNames(
          "w-full px-0 m-0 pb-10 -mb-[2px] bg-white",
          classes.container,
          isNavHidden ? "pt-10" : "pt-20",
        )}
        {...storyblokEditable(props.blok)}
      >
        <header className="relative header-padding w-full flex flex-row gap-12 lg:gap-14 overflow-hidden">
          <article className="lg:w-3/5">
            <div className="mt-10  md:mt-10 lg:mt-20 ">
              {items && <Breadcrumb items={items} />}
              <h1 className="text-3xl md:text-5xl text-blue7 font-semi-bold font-montserrat">
                {param ? param : heading}
              </h1>
            </div>
            {hasHeroVideo ? (
              <div
                className={`${classes.wrapperResponsive} ${classes.wrapperEmbed}`}
              >
                <div
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    allowTransparency={true}
                    src="https://adilo.bigcommand.com/watch/34J4NUQa"
                    title="Stress-Free Pet Transportation: Unlock the Magic of Quality Delivery with CitizenShipper"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            ) : (
              <Image
                className="lg:hidden float-right ml-2 mb-1 object-cover w-40 h-40 rounded-full"
                src={readImage(image2)}
                alt={image2.alt}
                width={732}
                height={1100}
                priority
              />
            )}

            <Markdown
              className={classNames(
                "mb-8 text-2xl text-blue5 font-normal prose-p:leading-7",
                classes.content,
              )}
            >
              {content}
            </Markdown>

            <aside className={"my-4 " + className + " gap-4"}>
              {stats &&
                stats.map(({ icon, markdown, className }, i) => (
                  <PrimaryCard
                    icon={icon}
                    markdown={markdown}
                    key={`pillars${i}`}
                    className={classNames(
                      className,
                      "prose-h4:text-blue7 prose-h4:font-semibold prose-h4:text-2xl prose-h4:mb-1 prose-p:text-[17px] bg-white sm:p-5",
                    )}
                  />
                ))}
            </aside>
            {showButton && (
              <PrimaryButton onClick={handleCTAPath} className="my-4 w-full">
                {buttonLabel}
              </PrimaryButton>
            )}
            {showHours && isInBusinessHours && (
              <p className="text-gray7 text-center text-base font-roboto">
                or Call us on{" "}
                <Anchor href="tel:9034768036">(903) 476-8036</Anchor> to get a
                Quote
              </p>
            )}
          </article>

          <div className="relative hidden lg:flex items-center w-2/5 mt-4">
            {hasHeroVideo ? (
              <div className={classes.wrapper}>
                <div
                  style={{
                    width: "100%",
                    height: "null",
                    position: "relative",
                    paddingTop: "56.25%",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    allowTransparency={true}
                    src="https://adilo.bigcommand.com/watch/34J4NUQa"
                    title="Stress-Free Pet Transportation: Unlock the Magic of Quality Delivery with CitizenShipper"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            ) : (
              <>
                <Image
                  className="absolute w-4/5 max-h-full h-auto object-contain animate-oddStack"
                  src={readImage(image1)}
                  alt={image1.alt}
                  width={732}
                  height={1100}
                  priority
                />
                <Image
                  className="absolute w-4/5 max-h-full h-auto object-contain animate-evenStack"
                  src={readImage(image2)}
                  alt={image2.alt}
                  width={1182}
                  height={1776}
                  priority
                />
              </>
            )}
          </div>
        </header>
      </section>
    </>
  )
}
