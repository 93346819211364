import get from "lodash/get"
import { addDays } from "date-fns"
import Cookies from "js-cookie"
export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isOk = re.test(email)

  if (!isOk) {
    return "The email must be a valid email address."
  }
  return null
}

export function validateLocationAddress(location) {
  if (location === null) {
    return "A valid address is required."
  }
  return null
}

const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const convertMetresToMiles = (meters) => meters * 0.000621371

const weightMarks = [
  { label: "x-small", value: 4 },
  { label: "small", value: 30 },
  { label: "medium", value: 60 },
  { label: "large", value: 90 },
  { label: "x-large", value: 200 },
]

const weightRanges = weightMarks
  .map((mark, index) => {
    if (index === 0 && weightMarks[1] != null) {
      return { label: mark.label, min: 1, max: mark.value }
    }
    if (index > 0 && index < weightMarks.length - 1) {
      return {
        label: mark.label,
        min: weightMarks[index - 1].value + 1,
        max: mark.value,
      }
    }
    if (index === weightMarks.length - 1) {
      return {
        label: mark.label,
        min: weightMarks[index - 1].value + 1,
        max: 200,
      }
    }
    return null
  })
  .filter(Boolean)

export const getSizeBasedOnWeight = (weight) => {
  const weightItem = weightRanges.find(
    (w) => w != null && weight >= w.min && weight <= w.max,
  )
  return weightItem ? weightItem.label : weightRanges[0].label
}

export const getDistance = (google, origin, destination) => {
  return new Promise((resolve, reject) => {
    if (!google || !google.maps) {
      return reject("Google Maps API not available")
    }
    const service = new google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [new google.maps.LatLng(origin.lat, origin.lng)],
        destinations: [
          new google.maps.LatLng(destination.lat, destination.lng),
        ],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        if (status === "OK") {
          resolve(response)
        } else {
          reject(status)
        }
      },
    )
  })
}

export const getMileage = async (google, origin, destination) => {
  try {
    const distanceInMeters = await getDistance(google, origin, destination)
    const distance = get(
      distanceInMeters,
      "rows[0].elements[0].distance.value",
      null,
    )
    if (distance != null) {
      return Math.round(convertMetresToMiles(distance))
    }
    throw new Error("No distance value returned")
  } catch (error) {
    console.warn(
      `Distance Matrix API failed: ${error}. Using Haversine fallback.`,
    )
    return getDistanceFromLatLonInMiles(
      origin.lat,
      origin.lng,
      destination.lat,
      destination.lng,
    )
  }
}

export const transformShipmentBody = async (
  values,
  animalType = "dog",
  google,
) => {
  const pickupDateRaw = get(values, "pickup.date", null)
  const pickupAddress = get(values, "pickup.address", {})
  const deliveryAddress = get(values, "delivery.address", {})
  const email = get(values, "email", "")
  const name =
    animalType === "dog" ? get(values, "dogName", "") : get(values, "name", "")
  const breed = get(values, "dogBreed", "") || get(values, "breed", "")
  const count = get(values, "count", 1)
  const size = get(values, "size")
  const weight = get(values, "weight")
  const pickupDate = pickupDateRaw
    ? new Date(pickupDateRaw).toISOString()
    : null
  const deliveryDateFlexibility = 7
  const deliveryDate = pickupDate
    ? addDays(new Date(pickupDate), deliveryDateFlexibility).toISOString()
    : null

  const origin = {
    lat: get(pickupAddress, "lat", 0),
    lng: get(pickupAddress, "lng", 0),
  }
  const destination = {
    lat: get(deliveryAddress, "lat", 0),
    lng: get(deliveryAddress, "lng", 0),
  }

  const mileage = await getMileage(google, origin, destination)

  const subcategoryCode = animalType === "dog" ? "dog" : "animal"
  const categoryCode = "pet"

  return {
    dinahShipmentBody: {
      mileage,
      pickup: {
        date: pickupDate,
        address_type: "residential",
        city: get(pickupAddress, "city", ""),
        lat: get(pickupAddress, "lat", 0),
        lng: get(pickupAddress, "lng", 0),
        state_code: get(pickupAddress, "state_code", ""),
        zip: get(pickupAddress, "zip", ""),
        address: get(pickupAddress, "formatted_address", ""),
      },
      delivery: {
        date: deliveryDate,
        city: get(deliveryAddress, "city", ""),
        lat: get(deliveryAddress, "lat", 0),
        lng: get(deliveryAddress, "lng", 0),
        state_code: get(deliveryAddress, "state_code", ""),
        zip: get(deliveryAddress, "zip", ""),
        address: get(deliveryAddress, "formatted_address", ""),
      },
      about: "",
      pickupDateFlexibility: "within 7 days",
      deliveryDateFlexibility,
      when_to_book: null,
      photos: [],
      savingImages: false,
      error: null,
      action: "save",
      equipment: [],
      services: [],
      url: "animals",
      category_code: categoryCode,
      category_attributes: {
        breed,
        weight,
        height: null,
        count: count.toString(),
        size,
        name,
      },
      isOlderThan8Weeks: true,
      subcategory_code: subcategoryCode,
      images: [],
      survey: [{ question: "What is your immediate need?", answer: null }],
    },
    bookingFormExtras: { offeringIds: [] },
  }
}

export const transformShipmentBodyForStorage = async (
  values,
  animalType = "dog",
  google,
) => {
  const pickupDateRaw = get(values, "pickup.date", null)
  const pickupAddress = get(values, "pickup.address", {})
  const deliveryAddress = get(values, "delivery.address", {})
  const name =
    animalType === "dog" ? get(values, "dogName", "") : get(values, "name", "")
  const breed = get(values, "dogBreed", "") || get(values, "breed", "")
  const count = get(values, "count", 1)
  const size = get(values, "size", 1)
  const weight = get(values, "weight")
  const pickupDate = pickupDateRaw
    ? new Date(pickupDateRaw).toISOString()
    : null
  const deliveryDateFlexibility = 7
  const deliveryDate = ""

  const origin = {
    lat: get(pickupAddress, "lat", 0),
    lng: get(pickupAddress, "lng", 0),
  }
  const destination = {
    lat: get(deliveryAddress, "lat", 0),
    lng: get(deliveryAddress, "lng", 0),
  }

  const mileage = await getMileage(google, origin, destination)

  const subcategoryCode = animalType === "dog" ? "dog" : "animal"
  const categoryCode = "pet"

  const transformedData = {
    mileage,
    pickup: {
      address_type: "residential",
      address: {
        city: get(pickupAddress, "city", ""),
        formatted_address: get(pickupAddress, "formatted_address", ""),
        lat: get(pickupAddress, "lat", 0),
        lng: get(pickupAddress, "lng", 0),
        state_code: get(pickupAddress, "state_code", ""),
        zip: get(pickupAddress, "zip", ""),
      },
      date: pickupDate,
    },
    delivery: {
      address: {
        city: get(deliveryAddress, "city", ""),
        formatted_address: get(deliveryAddress, "formatted_address", ""),
        lat: get(deliveryAddress, "lat", 0),
        lng: get(deliveryAddress, "lng", 0),
        state_code: get(deliveryAddress, "state_code", ""),
        zip: get(deliveryAddress, "zip", ""),
      },
      date: deliveryDate,
    },
    about: "",
    pickupDateFlexibility: "exact date",
    deliveryDateFlexibility,
    when_to_book: null,
    photos: [],
    savingImages: false,
    error: null,
    action: "save",
    equipment: [],
    services: [],
    url: "animals",
    category_code: categoryCode,
    category_attributes: {
      breed,
      weight,
      height: null,
      count: count.toString(),
      size,
      name,
    },
    isOlderThan8Weeks: true,
    subcategory_code: subcategoryCode,
  }

  return transformedData
}
