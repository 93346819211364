import { SbBlokData, storyblokEditable } from "@storyblok/react"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import LFLReviewCard, { ILFLReview } from "../../components/cards/LFLReviewCard"
import { AiFillStar } from "react-icons/ai"
import { Anchor } from "../../app/components/anchor"

export interface ILFLReviews extends SbBlokData {
  heading: string
  buttonLabel: string
  reviews: Array<ILFLReview & SbBlokData>
}

export default function LFLReviews({ blok }: { blok: ILFLReviews }) {
  const { heading, buttonLabel, reviews } = blok

  const mid = Math.ceil(reviews.length / 2)

  return (
    <section
      {...storyblokEditable(blok)}
      className="sm:mt-32 my-16 page-padding flex flex-col"
    >
      <h3 className="mb-14 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
        {heading}
      </h3>
      <section className="relative sm:max-h-[180vh] lg:max-h-screen grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="flex flex-col gap-8">
          {reviews.slice(0, mid).map((review, i) => (
            <LFLReviewCard review={review} key={i} />
          ))}
        </div>
        <div className="flex flex-col gap-8">
          {reviews.slice(mid).map((review, i) => (
            <LFLReviewCard review={review} key={i} />
          ))}
        </div>
        {/* <div className="hidden md:block absolute bottom-0 left-0 w-full h-2/5 bg-gradient-to-b from-transparent to-white"></div> */}
      </section>
      <PrimaryButton className="w-full md:w-fit mt-8 md:mt-0 md:mx-auto rounded-lg">
        <Anchor className="hover:text-white text-white" href="">
          {buttonLabel}
        </Anchor>
      </PrimaryButton>
    </section>
  )
}
