import React, { FC } from "react"
import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import { Anchor } from "../../app/components/anchor"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

import { BlokImage } from ".."
import { readImage } from "../../app/utils/read-image"

type Image = {
  src: string
  alt: string
}

interface Item {
  description: string
  tagline: string
  image: BlokImage | Image
  title?: string
  buttonLink?: string
  buttonLabel?: string
}

export interface IItem extends SbBlokData {
  items?: Item[]
  title?: string
  subTitle?: string
  coloredTitle?: string
}
const PetShippingCardSetItem: FC<Item> = ({
  tagline,
  image,
  description,
  buttonLink,
  buttonLabel,
  title,
}) => {
  return (
    <article className="border bg-secondary-100 border-secondary-300 rounded-3xl md:flex gap-x-12 items-start mb-10 transition-opacity duration-500 ease-in-out p-4">
      <section className="mx-auto text-center max-w-[424px] md:min-h-[300px] max-h-[554px] px-6 pb-10">
        <small className="text-xl text-gray53 font-merriweather py-4">
          {" "}
          {tagline}
        </small>
        <div className="mx-auto flex justify-center items-center mt-4 border border-secondary-500 border-opacity-40 rounded-full w-[200px] h-[200px]">
          <Image
            className="object-cover w-[184px] h-[184px] rounded-full"
            src={readImage(image)}
            alt={image?.alt || ""}
            width={184}
            height={184}
          />
        </div>

        <div className="mt-7">
          <div className="text-2xl  mt-3 font-merriweather text-secondary-500">
            {title}
          </div>
          <p className="text-gray14 my-4 text-xl font-inter ">{description}</p>
          {buttonLink && (
            <Anchor
              className="font-inter block mt-6 border border-blue7 text-blue7 hover:text-black hover:border-black uppercase text-center py-3 no-underline text-md hover:no-underline"
              href={buttonLink}
            >
              {buttonLabel}
            </Anchor>
          )}
        </div>
      </section>
    </article>
  )
}

const PetShippingCardSet = ({ blok }: { blok: IItem }) => {
  const { items, title } = blok
  return (
    <section
      id="pet-shipping-card"
      className="max-w-[1334px] mx-auto px-4 my-10 md:my-14 md:pt-10 lg:my-8 md:px-8 lg:px-10"
    >
      <p className="font-merriweather mb-6 text-3xl leading-[48px] lg:text-[40px] lg:leading-[72px] text-center">
        {title}
      </p>

      <div className="px-2 lg:flex gap-8 items-center justify-center sm:max-w-md md:max-w-none mx-auto">
        {items?.map((item) => (
          <div
            key={item.title}
            className="md:max-lg:max-w-md lg:max-w-none mx-auto"
          >
            <PetShippingCardSetItem {...item} />
          </div>
        ))}
      </div>
    </section>
  )
}
export default PetShippingCardSet
