import { SbBlokData } from "@storyblok/react"
import { HTMLAttributes, FC } from "react"
import { BlokImage } from ".."
import { HomepageMeetDriver } from "../../app/sections/homepage-meet-drivers"

export type TestimonyType = {
  text: string
  author: string
  origin: string
}

export type HomeTestimonyProps = TestimonyType & HTMLAttributes<HTMLElement>

export const HomeTestimony: FC<HomeTestimonyProps> = (props) => {
  return <div {...props} />
}

export type HomeMeetDriversCardType = {
  id: number
  shipments: string
  image: BlokImage
  alt?: string
  testimony: TestimonyType[]
  ratingCompany: string
  ratingValue: number
}

export type HomeMeetDriversCardProps = TestimonyType &
  HTMLAttributes<HTMLElement>

export const HomeMeetDriversCard: FC<HomeMeetDriversCardProps> = (props) => {
  return <div {...props} />
}

export interface IHomeMeetTheDrivers extends SbBlokData {
  title?: string
  headline?: string
  drivers: HomeMeetDriversCardType[]
}

const HomeMeetTheDrivers = ({ blok }: { blok: IHomeMeetTheDrivers }) => {
  const { drivers, title, headline } = blok

  const driversCrew =
    drivers?.map((item) => ({
      id: item.id,
      shipments: Number(item.shipments),
      image: item.image,
      alt: item.alt || "",
      testimonyDescription: item.testimony[0]?.text || "",
      ratingCompany: item.ratingCompany,
    })) ?? []

  return (
    <HomepageMeetDriver
      blok={{
        title: title || "",
        description: headline || "",
        driversCrew,
      }}
    />
  )
}

export default HomeMeetTheDrivers
