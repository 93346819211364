import Image from "next/image"
import { BlokImage } from ".."
import { readImage } from "../../app/utils/read-image"
import { Anchor } from "../../app/components/anchor"

const points = [
  {
    title: "Top-rated, industry-leading transport ",
    text:
      "CitizenShipper is the highest 5-star-rated transport marketplace in the world. ",
    icon: "/images/top-rated.webp",
    height: 36,
    width: 44,
  },
  {
    title: "Booking Assurance Guarantee",
    text:
      "Rest easy with refund protection, driver replacement guarantee and dedicated support.",
    citeLink:
      "https://help.citizenshipper.com/hc/en-us/articles/360041203251-CitizenShipper-Booking-Assurance-Guarantee",
    citeLinkText: "1",
    icon: "/images/assurance.webp",
    height: 44,
    width: 44,
  },
  {
    title: "$1k Pet Protection Coverage",
    text:
      "CitizenShipper is the first and only transport platform that covers every journey with a $1,000 Pet Protection Plan. Your pet's safety and comfort are always #1.",
    icon: "/images/protection.webp",
    height: 46,
    width: 38,
  },
  {
    title: "Drivers you can trust",
    text:
      "Enjoy peace of mind with stringent background checks, detailed profiles and direct contact with every driver.",
    citeLink:
      "https://help.citizenshipper.com/hc/en-us/articles/203050235-Why-is-Background-Screening-so-Important-",
    citeLinkText: "2",
    icon: "/images/trust-icon.webp",
    height: 54,
    width: 54,
  },
  {
    title: "Stellar customer support",
    text:
      "Our team responds to questions 324% faster than the industry average!",
    icon: "/images/support-icon.webp",
    height: 54,
    width: 54,
  },
]
type ICoverageProps = {
  blok?: {
    backgroundImage: BlokImage
  }
}

export default function Coverage(props: ICoverageProps) {
  return (
    <section className="w-full bg-gray3 relative">
      {props.blok?.backgroundImage && (
        <div className="absolute inset-0 z-0 opacity-5">
          <Image
            src={readImage(props.blok?.backgroundImage)}
            layout="fill"
            objectFit="cover"
            quality={100}
            alt={props.blok?.backgroundImage.alt}
          />
        </div>
      )}
      <article className="page-padding">
        <h3 className="mb-16 text-center text-blue7 text-4xl font-montserrat">
          We've got you covered
        </h3>
        <section className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {points.map(({ title, text, icon, citeLink, citeLinkText }) => (
            <div
              className="md:last:col-span-2 md:last:w-1/2 mx-auto flex items-start"
              key={title}
            >
              <Image
                className="min-w-[40px] object-contain"
                src={icon}
                alt={title}
                height={54}
                width={54}
              />
              <div className="ml-4 grow">
                <h4 className="font-montserrat font-bold text-2xl">{title}</h4>
                <p>
                  {text}
                  {citeLink && (
                    <cite className="underline indent-1 relative -top-2 inline-flex text-base">
                      <Anchor href={citeLink} newTab={true}>
                        {citeLinkText}
                      </Anchor>
                    </cite>
                  )}
                </p>
              </div>
            </div>
          ))}
        </section>
      </article>
    </section>
  )
}
