import { storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import classNames from "classnames"
import { BlokImage, ConditionalBlok } from ".."
import Markdown from "../../components/content/Markdown"
import ListCard, { IListCardProps } from "../../components/cards/ListCard"
import ListSubsection, { IListSubsection } from "../ListSubsection"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import DropdownSection, { IDropdownSection } from "./DropdownSection"
import AdaptiveGrid, { IAdaptiveGrid } from "./layout/AdaptiveGrid"
import { readImage } from "../../app/utils/read-image"

export interface IImageRightContent {
  header: string
  markdown: string
  image: BlokImage
  blocks: Array<IListCardProps | IDropdownSection | IListSubsection>
  imageJustify: "justify-start" | "justify-center" | "justify-end"
  imageAlign: "items-start" | "items-center" | "items-end"
  buttonLabel: string
  pathUrl?: string
  extraSection?: IAdaptiveGrid[]
}
type IImageRightContentProps = ConditionalBlok<IImageRightContent> & {
  className?: string
  host?: Record<string, string>
  noPadding?: boolean
}

export default function ImageRightContent(props: IImageRightContentProps) {
  const { noBlok, className, host, noPadding } = props
  const appHost = host?.app || process.env.NEXT_PUBLIC_APP_HOST || ""
  const {
    header,
    markdown,
    blocks,
    image,
    imageAlign,
    imageJustify,
    buttonLabel,
    extraSection,

    pathUrl = `${appHost}/shipments/new`,
  } = noBlok ? props : props.blok

  return (
    <>
      <article
        className={classNames(
          className,
          "relative  w-full flex xl:gap-40 lg:gap-32 md:gap-24",
          { "page-padding": !noPadding },
        )}
        {...(!noBlok && storyblokEditable(props.blok))}
      >
        <Image
          className="-mb-1 bg-cover bg-no-repeat -z-10"
          src="/images/bg_1.webp"
          alt="CitizenShipper"
          fill
          priority
        />
        <section className="w-full lg:w-3/5 h-fit space-y-8">
          {header && (
            <h4 className="font-montserrat font-normal  text-4xl text-blue7">
              {header}
            </h4>
          )}
          <div className="lg:hidden relative h-[40vh]">
            <Image
              className="w-full object-right object-contain rounded-lg"
              src={readImage(image)}
              alt={image.alt}
              fill
            />
          </div>
          {markdown && <Markdown className="">{markdown}</Markdown>}
          <aside className="flex flex-col space-y-8">
            {blocks &&
              blocks.map((block, i) => {
                let { icon, header, markdown, headerColor } = block

                if (block.component === "listCard") {
                  return (
                    <ListCard
                      icon={icon as BlokImage}
                      header={header as string}
                      headerColor={(headerColor as "blue7") || "blue5"}
                      markdown={markdown}
                      component="listCard"
                      key={`imageRightContainer/${i}`}
                      className="prose-h4:text-2xl prose-h4:mb-1 bg-transparent"
                    />
                  )
                } else if (block.component === "dropdownSection") {
                  return (
                    <DropdownSection
                      key={i}
                      blok={{
                        markdown: block.markdown,
                        details: block.details as {
                          summary: string
                          content: string
                        }[],
                        _uid: block._uid,
                        component: block.component,
                      }}
                    />
                  )
                } else if (block.component === "listSubsection") {
                  return (
                    <ListSubsection
                      key={i}
                      blok={{
                        header: block.header as string,
                        listItems: block.listItems as {
                          icon: BlokImage
                          content: string
                        }[],
                        markdown: block.markdown,
                        _uid: block._uid,
                        component: block.component,
                      }}
                    />
                  )
                }
              })}
          </aside>
        </section>
        <div
          className={`relative hidden w-2/5 lg:flex ${imageAlign} ${imageJustify}`}
        >
          <Image
            className="object-contain rounded-lg"
            src={readImage(image)}
            alt={image.alt}
            fill
          />
        </div>
      </article>
      {buttonLabel && (
        <div className="w-full flex justify-center">
          <PrimaryButton
            className="mb-16 mx-16 lg:px-32 rounded-lg"
            onClick={() => window.location.assign(pathUrl)}
          >
            {buttonLabel}
          </PrimaryButton>
        </div>
      )}
      {extraSection?.length ? (
        <AdaptiveGrid noBlok {...extraSection[0]} className="pt-0 " />
      ) : null}
    </>
  )
}
