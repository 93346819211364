import React from "react"
import dynamic from "next/dynamic"
import { SbBlokData } from "@storyblok/react"
import { BlokImage } from ".."

const Reviews = dynamic(() =>
  import("../../app/sections/homepage-reviews").then(
    (mod) => mod.HomepageReviews,
  ),
)
const Showcase = dynamic(() => import("../../sections/homepage-showcase"))
const Offer = dynamic(() => import("../../sections/homepage-offer"))

export type ShipmentsDataType = {
  id: number
  imageSrc: BlokImage
  amount: number
  breed: string
  mileage: string
  city: string
}

export interface IShowCaseProps {
  title?: string
  data: ShipmentsDataType[]
  matchTitle?: string
  matchSubtitle?: string
  feedback?: string
  authorName?: string
  platform?: string
}

export type ShowCaseCardProps = IShowCaseProps &
  React.HTMLAttributes<HTMLElement>

export type ReviewItem = {
  reviewer: string
  icon: BlokImage
  title?: string
  content: string
  images?: string
  category?: string
  rating?: number
  logo?: string
}

export interface IHomeContent extends SbBlokData, IShowCaseProps {
  title: string
  buttonTitle: string
  buttonLink: string
  hasDarkBlueTheme: boolean
  reviews: ReviewItem[]
  reviewCount: string
  reviewTitle: string
}

export type ReviewSnippetsCardProps = ReviewItem &
  React.HTMLAttributes<HTMLElement>

export const HomeReviewItem: React.FC<ReviewSnippetsCardProps> = (props) => {
  return <div {...props} />
}

export const HomePriceShowCaseItem: React.FC<ShowCaseCardProps> = (props) => {
  return <div {...props} />
}

const HomeContent = ({ blok }: { blok: IHomeContent }) => {
  const {
    reviews,
    title,
    matchSubtitle,
    matchTitle,
    platform,
    feedback,
    authorName,
    data,
    reviewCount,
    reviewTitle,
    buttonLink,
    buttonTitle,
  } = blok

  const reviewsData =
    reviews?.map((review) => ({
      reviewer: review.reviewer,
      icon: review.icon,
      title: review.title,
      category: review.category,
      description: review.content,
      images: review.images
        ? [
            {
              id: 0,
              alt: "Review image",
              name: "Review image",
              focus: "",
              title: "",
              source: "",
              filename: review.images,
              copyright: "",
              fieldtype: "asset",
              description: "Review image",
            },
          ]
        : undefined,
    })) || []

  return (
    <>
      <Reviews
        blok={{
          title: reviewTitle,
          numberOfReviews: reviewCount,
          reviews: reviewsData,
        }}
      />
      <Showcase
        title={title}
        data={data}
        matchTitle={matchTitle}
        matchSubtitle={matchSubtitle}
        feedback={feedback}
        authorName={authorName}
        platform={platform}
      />
      <Offer />
    </>
  )
}

export default HomeContent
