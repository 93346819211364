import { Anchor } from "../../app/components/anchor"
import Image from "next/image"
import bbbRating from "../../assets/images/bbb-rating.webp"

const ProductFooter = () => {
  return (
    <section
      id="product-footer"
      className="py-4 max-w-7xl mx-auto border-t mt-12 md:mt-24 pt-8 px-4 lg:px-2 text-sm md:text-base"
    >
      <div>
        <p>Useful Links</p>
        <div className="grid grid-cols-3 gap-x-0 gap-y-2 md:inline-flex gap-1 md:divide-x divide-blue7">
          <Anchor
            href="https://help.citizenshipper.com/hc/en-us/categories/200277749-Terms-of-Service-Agreements"
            className="pr-1"
          >
            Terms and Conditions
          </Anchor>

          <Anchor
            href="https://help.citizenshipper.com/hc/en-us/articles/204865815-Privacy-Policy"
            className="px-1"
          >
            Privacy Policy
          </Anchor>

          <Anchor href={`/about-us`} prefetch={false} className="pl-1">
            About Us
          </Anchor>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-8 py-4 max-w-7xl my-6 text-sm md:text-base">
        <aside>
          <p className="mx-0 mb-1 text-sm">
            Contact us at{" "}
            <a
              className="text-black no-underline"
              href="mailto:support@citizenshipper.com"
            >
              support@citizenshipper.com
            </a>
          </p>
          <p className="m-0 text-sm">
            421 East Broad Street, Suite 4, #800, Mineola, TX, 75773
          </p>
        </aside>
        <div className="flex flex-row justify-between items-center gap-2 md:gap-4">
          <Image
            src="/icons/powered-by-stripe.webp"
            alt="stripe"
            width="150"
            height="30"
            loading="lazy"
            className="w-[150px] h-[30px]"
          />

          <Image
            src={bbbRating.src}
            alt="CitizenShipper is a BBB Accredited Business."
            width="135"
            height="40"
            className="w-[135px] h-[40px]"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  )
}
export default ProductFooter
