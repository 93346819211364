import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlokImage } from ".."
import Image from "next/image"
import PrimaryCard from "../../components/cards/PrimaryCard"
import { readImage } from "../../app/utils/read-image"

export interface IChoosingShippers extends SbBlokData {
  heading: string
  content: string
  blocks: Array<
    {
      icon: BlokImage
      markdown: string
    } & SbBlokData
  >
  image: BlokImage
}

export default function ChoosingShippers({
  blok,
}: {
  blok: IChoosingShippers
}) {
  const { heading, content, blocks, image } = blok

  const mid = Math.ceil(blocks.length / 2)

  return (
    <article
      className="relative mx-auto page-padding w-full flex flex-col py-20 md:items-center bg-gray11"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-8 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
        {heading}
      </h3>
      {image && (
        <div className="relative w-full md:w-1/2 max-h-72 mb-5 sm:max-h-fit h-96">
          <Image
            className="h-fit object-cover rounded-lg"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      )}
      <section className="md:px-32 md:text-center text-2xl mb-16">
        {content}
      </section>
      <section className="relative grid grid-cols-1 md:grid-cols-2 gap-8">
        {blocks.map(({ icon, markdown }, i) => (
          <PrimaryCard
            icon={icon}
            markdown={markdown}
            key={`shipFactors/${i}`}
            className="bg-white"
            component="primaryCard"
          />
        ))}
      </section>
    </article>
  )
}
