import dynamic from "next/dynamic"

import { HomeHero } from "../app/sections/hero"
import AdaptiveGrid from "./sections/layout/AdaptiveGrid"
import ArticlesSection from "./sections/ArticlesSection"
import AsFeaturedIn, { FeaturedInItem } from "./static/AsFeaturedIn"
import Button from "./sections/Button"
import CTAVariant from "./sections/CTAVariant"
import Calculator from "./sections/Calculator"
import CheapestAutoTransport from "./sections/car-shipping/CheapestAutoTransport"
import CheckList from "./sections/CheckList"
import ChoosingShippers from "./sections/ChoosingShippers"
import CostBreakdown from "./sections/CostBreakdown"
import Coverage from "./static/Coverage"
import DropdownSection from "./sections/DropdownSection"
import ExpenseCard from "../components/cards/ExpenseCard"
import FAQS from "./sections/FAQs"
import Factors from "./sections/Factors"
import Footer from "./static/Footer"
import GetQuotes from "./sections/GetQuotes"
import HeaderDescImage from "./sections/HeaderDescImage"
import Heading from "./sections/Heading"
import HowMuchDoesItCostToMoveAnRV from "./sections/rv-transport/HowMuchDoesItCostToMoveAnRV"
import ImageLeftContent from "./sections/ImageLeftContent"
import ImageRightContent from "./sections/ImageRightContent"
import Introduction from "./sections/Introduction"
import LFLReviewCard from "../components/cards/LFLReviewCard"
import LFLReviews from "./sections/LFLReviews"
import LeadingThePack from "./sections/pet-transportation/LeadingThePack"
import ListSubsection from "./ListSubsection"
import LinkBlock, { LinkBlockItem } from "../components/link-block"
import MarkdownAndDetails from "./sections/MarkdownAndDetails"
import MarkdownAndTable from "./sections/MarkdownAndTable"
import MarkdownSection from "./sections/MarkdownSection"
import MeetTheDrivers from "./sections/MeetTheDrivers"
import MotoIntro from "./sections/MotoIntro"
import NoHassleCTA from "./sections/NoHassleCTA"
import OurPromise from "./sections/pet-transportation/OurPromise"
import Features from "./sections/Features"
import Page from "./containers/Page"
import PageNav, {
  NavCategoryItem,
  SubNavCategoryItem,
} from "./sections/PageNav"
import PetTransportationHeader from "./sections/pet-transportation/PetTransportationHeader"
import PillarsArticle from "./sections/pillars/PillarsArticle"
import PillarsHeader from "./sections/pillars/PillarsHeader"
import PillarsReview from "./sections/pillars/PillarReview"
import PillarsSubReview from "./sections/pillars/PillarsSubReview"
import PillarsTrustPilot from "./sections/pillars/PillarsTrustPilot"
import PillarsLeadingPack from "./sections/pillars/PillarsLeadingPack"
import PillarsOurPromise from "./sections/pillars/PillarsOurPromise"
import PillarsFAQ from "./sections/pillars/PillarsFAQ"
import PillarsPeaceOfMind from "./sections/pillars/PillarsPeaceOfMind"
import PillarsMeetDrivers from "./sections/pillars/PillarsMeetDrivers"
import PillarSingleReviewCard from "./sections/pillars/PillarSingleReviewCard"
import Preparation from "./sections/Preparation"
import PreparingForCarDelivery from "./sections/car-shipping/PreparingForCarDelivery"
import PreparingForHouseholdGoodsShipping from "./sections/household-goods-shipping/PreparingForHouseholdGoodsShipping"
import Process from "./sections/Process"
import RVInvestment from "./sections/rv-transport/RVInvestment"
import RatingsBlock from "./sections/RatingsBlock"
import Section from "./sections/Section"
import ShipFactors from "./sections/ShipFactors"
import ShipSteps from "./sections/ShipSteps"
import Stats from "./static/Stats"
import SuccessStories from "./sections/cost-to-ship/SuccessStories"
import Support from "./sections/Support"
import TableColumnSection from "./sections/TableColumnSection"
import TableSection from "./sections/layout/TableSection"
import TextColumnsSection from "./sections/TextColumnsSection"
import WaysToShip from "./sections/WaysToShip"
import YoutubeEmbed from "./sections/YoutubeEmbed"
import MeetTheDriversCard from "../components/cards/MeetTheDriverCard"
import FeatureCards, { FeatureCardItem } from "../components/cards/FeatureCard"
import MeetTheDriversV2 from "./sections/vehicle-transportation/MeetTheDrversV2"
import ReviewSnippets, {
  ReviewSnippetsCard,
} from "./sections/vehicle-transportation/ReviewSnippet"
import HeroSection from "./home-page/HeroSection"
import HomeContent, {
  HomePriceShowCaseItem,
  HomeReviewItem,
} from "./home-page/HomeContent"
import HomeMeetTheDrivers, {
  HomeMeetDriversCard,
  HomeTestimony,
} from "./home-page/HomeMeetDrivers"
import HomeSupport, { HomeSupportItem } from "./home-page/HomeSupport"
import Breadcrumb, { BreadcrumbItem } from "./sections/Breadcrumb"
import PageHeader2 from "./sections/PageHeader2"
import PageHeader3 from "./sections/PageHeader3"
import OfferCards, { OfferCardItem } from "../components/cards/OfferCard"
import PremiumHero from "../components/premium/hero"
import PremiumNavbar from "../components/premium/navbar"
import PremiumAsFeaturedIn from "../components/premium/as-featured-in"
import PremiumPriceTag from "../components/premium/price-tag"
import PremiumAuthorReview from "../components/premium/author-review"
import PremiumPetOnTheRoad from "../components/premium/pet-on-the-road"
import PremiumOurPromise from "../components/premium/our-promise"
import PremiumStats from "../components/premium/stats"
import PremiumFooter from "../components/premium/footer"
import WhyCitizenshipper from "../components/product/why-citizenshipper"
import Services from "../components/product/services"
import ProductAsFeaturedIn from "../components/product/as-featured-in"
import ProductFooter from "../components/product/footer"
import ProductHero from "../components/product/hero"
import ProductHeroVariant from "../components/product/hero-variant"
import ProductNavbar from "../components/product/navbar"
import ThumbnailSet from "./sections/ThumbnailSet"
import CardSet from "./sections/CardSet"
import PetsHero from "./sections/PetsHero"
import PartnersHero from "../components/partners/hero"
import PartnersSection from "../components/partners/section"
import BusinessOpportunityForm from "../components/partners/form"
import PetShippingCardSet from "./sections/PetShippingCardSet"
import PetsPassion from "./sections/Passion"
import CostCard from "./sections/cost-to-ship/TransportCost"
import Testimonials from "../components/testimonials/Testimonials"

const PageHeader = dynamic(() => import("./sections/PageHeader"))
const CostFactors = dynamic(() => import("./sections/cost-to-ship/CostFactors"))
const TransportCost = dynamic(
  () => import("./sections/cost-to-ship/TransportCost"),
)
const CustomerReviews = dynamic(() => import("./sections/CustomerReviews"))
const PolaroidsHeader = dynamic(() => import("./sections/PolaroidsHeader"))

const components = {
  page: Page,
  pageNav: PageNav,
  pageHeader: PageHeader,
  customerReviews: CustomerReviews,
  meetTheDrivers: MeetTheDrivers,
  process: Process,
  checkList: CheckList,
  getQuotes: GetQuotes,
  FAQs: FAQS,
  ratingsBlock: RatingsBlock,
  introduction: Introduction,
  asFeaturedIn: AsFeaturedIn,
  FeaturedInItem,
  waysToShip: WaysToShip,
  LFLReviews: LFLReviews,
  LFLReviewCard: LFLReviewCard,
  choosingShippers: ChoosingShippers,
  shipFactors: ShipFactors,
  shipSteps: ShipSteps,
  markdownAndDetails: MarkdownAndDetails,
  calculator: Calculator,
  stats: Stats,
  cheapestAutoTransport: CheapestAutoTransport,
  motoIntro: MotoIntro,
  preparation: Preparation,
  support: Support,
  mdSection: MarkdownSection,
  noHassleCTA: NoHassleCTA,
  preparingForCarDelivery: PreparingForCarDelivery,
  factors: Factors,
  howToShipHouseholdGoods: PreparingForHouseholdGoodsShipping,
  rvInvestment: RVInvestment,
  howMuchDoesItCostToMoveAnRV: HowMuchDoesItCostToMoveAnRV,
  markdownAndTable: MarkdownAndTable,
  petTransportationHeader: PetTransportationHeader,
  ourPromise: OurPromise,
  imageLeftContent: ImageLeftContent,
  imageRightContent: ImageRightContent,
  section: Section,
  costBreakdown: CostBreakdown,
  footer: Footer,
  dropdownSection: DropdownSection,
  listSubsection: ListSubsection,
  youtubeEmbed: YoutubeEmbed,
  articlesSection: ArticlesSection,
  tableSection: TableSection,
  adaptiveGrid: AdaptiveGrid,
  leadingThePack: LeadingThePack,
  polaroidsHeader: PolaroidsHeader,
  transportCost: TransportCost,
  costFactors: CostFactors,
  successStories: SuccessStories,
  expenseCard: ExpenseCard,
  headerDescImage: HeaderDescImage,
  textColumnsSection: TextColumnsSection,
  tableColumnSection: TableColumnSection,
  CTAVariant,
  Coverage,
  Button,
  heading: Heading,
  pillarsHeader: PillarsHeader,
  pillarsArticle: PillarsArticle,
  pillarsReview: PillarsReview,
  PillarsSubReview,
  PillarsTrustPilot,
  PillarsOurPromise,
  PillarsLeadingPack,
  PillarsPeaceOfMind,
  PillarsMeetDrivers,
  PillarSingleReviewCard,
  PillarsFAQ,
  MeetTheDriversV2,
  MeetTheDriversCard,
  ReviewSnippets,
  ReviewSnippetsCard,
  HomeContent,
  HeroSection,
  HomeReviewItem,
  HomePriceShowCaseItem,
  HomeTestimony,
  HomeMeetTheDrivers,
  HomeMeetDriversCard,
  HomeSupport,
  HomeSupportItem,
  NavCategoryItem,
  SubNavCategoryItem,
  BreadcrumbItem,
  Breadcrumb,
  LinkBlock,
  LinkBlockItem,
  PageHeader2,
  PageHeader3,
  HomeHero,
  FeatureCards,
  FeatureCardItem,
  OfferCards,
  OfferCardItem,
  PremiumHero,
  PremiumNavbar,
  PremiumAsFeaturedIn,
  PremiumPriceTag,
  PremiumAuthorReview,
  PremiumPetOnTheRoad,
  PremiumOurPromise,
  PremiumStats,
  PremiumFooter,
  WhyCitizenshipper,
  Services,
  ProductHero,
  ProductHeroVariant,
  ProductFooter,
  ProductNavbar,
  ProductAsFeaturedIn,
  ThumbnailSet,
  CardSet,
  PetsHero,
  Features,
  PartnersHero,
  PartnersSection,
  PetShippingCardSet,
  PetsPassion,
  BusinessOpportunityForm,
  CostCard,
  Testimonials,
}

export default components
export type {
  BlokImage,
  IPageBlok,
  IStoryBlokComponent,
  BlokTable,
  ConditionalBlok,
} from "./types"
