import React, { FC, HTMLAttributes } from "react"
import dynamic from "next/dynamic"
import { SbBlokData } from "@storyblok/react"
import { BlokImage } from ".."
import type { HomepageSupportProps } from "../../app/sections/homepage-support"

const Support = dynamic<HomepageSupportProps>(() =>
  import("../../app/sections/homepage-support").then(
    (mod) => mod.HomepageSupport,
  ),
)

export type ShipmentsDataType = {
  id: number
  imageSrc: string
  amount: number
  breed: string
  mileage: string
  city: string
}

export interface IShowCaseProps {
  title?: string
  data: ShipmentsDataType[]
  matchTitle?: string
  matchSubtitle?: string
  feedback?: string
  authorName?: string
  platform?: string
}

export type ShowCaseCardProps = IShowCaseProps & HTMLAttributes<HTMLElement>

export type SupportItem = {
  name: string
  position: string
  image: BlokImage
  testimony: {
    text: string
    author: string
    origin: string
  }[]
}

export interface IHomeSupport extends SbBlokData, IShowCaseProps {
  title?: string
  supportData?: SupportItem[]
  headline?: string
  source?: string
  statistics?: string
}

export type SupportItemProps = SupportItem & HTMLAttributes<HTMLElement>

export const HomeSupportItem: FC<SupportItemProps> = (props) => {
  return <div {...props} />
}

const HomeSupport = ({ blok }: { blok: IHomeSupport }) => {
  const { title, headline: description, statistics, supportData, source } = blok

  const team =
    supportData?.map((item, index) => ({
      id: index,
      name: item.name,
      position: item.position,
      image: item.image,
      review: item.testimony[0].text,
      reviewer: item.testimony[0].author,
    })) ?? []

  return (
    <section className="page-padding">
      <Support
        blok={{
          title: title || "",
          description: description || "",
          source: source || "",
          team,
          statistics: statistics || "",
        }}
      />
    </section>
  )
}

export default HomeSupport
