import React, { useEffect, useRef, useState } from "react"
export const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  testId,
}: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleSelect = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        name={name}
        type="button"
        data-testid={testId}
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-2 mt-4 focus:outline-none text-sm text-left  bg-gray50 h-[56px] border-b text-neutral-600 border-black"
      >
        {value || placeholder}
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border rounded-md shadow-md p-4 mt-1 mx-0">
          {options.map((option: string) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm -mx-4 border-b border-gray-200 last-of-type:border-none"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
export default CustomSelect
