import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { Anchor } from "../../app/components/anchor"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import Markdown from "../../components/content/Markdown"
import useCTARoute from "../../util/useCTARoute"
import { env } from "../../config"

export interface ICTAVariant extends SbBlokData {
  topDetails: string
  heading: string
  bottomDetails: string
  buttonLabel: string
  bgColor: "white" | "gray3"
  path?: string
}

export default function CTAVariant({
  blok,
  host,
}: {
  blok: ICTAVariant
  host?: Record<string, string>
}) {
  const {
    heading,
    topDetails,
    bottomDetails,
    buttonLabel,
    bgColor,
    path,
  } = blok

  const { route } = useCTARoute()

  return (
    <section
      className={`relative page-padding w-full flex flex-col items-center text-center bg-${bgColor}`}
      {...storyblokEditable(blok)}
    >
      <p className="max-w-prose mb-10 font-base text-xl">{topDetails}</p>
      <h3 className="max-w-prose mb-10 font-montserrat text-blue7 text-4xl">
        {heading}
      </h3>
      <Anchor
        className="max-w-prose w-full text-white hover:text-white no-underline hover:no-underline"
        href={path ? `/${path}` : route}
      >
        <PrimaryButton className="w-full rounded-lg mb-10">
          {buttonLabel}
        </PrimaryButton>
      </Anchor>
      <Markdown className="max-w-prose font-base text-lg">
        {bottomDetails}
      </Markdown>
    </section>
  )
}
