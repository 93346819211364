import Image from "next/image"
import { Anchor } from "../app/components/anchor"
import StarRating from "./StarRating"
import { useReviewsContext } from "../context/ReviewsContext"

export default function TrustpilotGoogleReviews({
  className,
}: {
  className?: string
}) {
  const { blokReviews } = useReviewsContext()
  return (
    <div className={"flex justify-center items-center gap-4 " + className}>
      <section className="w-fit px-4 md:px-8 py-4 rounded-2xl shadow-md bg-white">
        <Image
          className="mb-5 h-8 w-auto"
          src="/icons/google.svg"
          alt="google logo"
          width={100}
          height={20}
        />
        <p className="text-sm mb-2">Average Rating</p>
        <div className="w-full flex flex-col sm:flex-row text-2xl sm:items-center">
          {blokReviews?.googleRatings}
          <StarRating value={5} className="sm:ml-2" />
        </div>
        <Anchor
          className="mt-2"
          href="https://www.google.com/maps/place/CitizenShipper/@32.2908037,-95.3054722,17z/data=!4m7!3m6!1s0x0:0xaa0dc85bb2e33171!8m2!3d32.2908037!4d-95.3054722!9m1!1b1?hl=en"
          target="_blank"
          rel="noreferrer"
          passHref
        >
          {blokReviews?.googleReviews} reviews
        </Anchor>
      </section>
      <section className="w-fit px-4 md:px-8 py-4 rounded-2xl shadow-md bg-white">
        <Image
          className="mb-5 h-8 w-auto"
          src="/icons/trust-pilot.svg"
          alt="trust pilot logo"
          width={100}
          height={20}
        />
        <p className="text-sm mb-2">Average Rating</p>
        <div className="w-full flex flex-col sm:flex-row text-2xl sm:items-center">
          {blokReviews?.trustpilotRatings}
          <StarRating value={5} className="sm:ml-2" />
        </div>
        <Anchor
          className="mt-2"
          href="https://www.trustpilot.com/review/citizenshipper.com?stars=5#:~:text=Reply%20from%20CitizenShipper"
          target="_blank"
          passHref
        >
          {blokReviews?.trustpilotReviews} reviews
        </Anchor>
      </section>
    </div>
  )
}
