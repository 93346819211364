import axios from "axios"
import { env } from "../config"

const helpers = {
  getFeaturedShipments: (categoryCode) =>
    axios.get(
      `${env("API_HOST")}/api/featured_shipments?category_code=${categoryCode}`,
    ),

  getRecentBookings: (categoryCode) =>
    axios.get(
      `${env("API_HOST")}/api/recent_bookings?category_code=${categoryCode}`,
    ),

  getReviews: (pageNumber = 1) =>
    axios.get(
      `https://daedalus.citizenshipper.com/api/reviews?page=${pageNumber}`,
    ),

  getCityExportData: (city, state) => {
    const url = `${env(
      "DAEDALUS_API_HOST",
    )}/api/shipments/recent/from?state=${state}&city=${city}`
    console.log("Calling export API:", url)
    return axios.get(url)
  },

  getCityImportData: (city, state) => {
    const url = `${env(
      "DAEDALUS_API_HOST",
    )}/api/shipments/recent/to?state=${state}&city=${city}`
    console.log("Calling import API:", url)
    return axios.get(url)
  },

  getStateExportData: (state) =>
    axios.get(
      `${env("DAEDALUS_API_HOST")}/api/shipments/recent/from?state=${state}`,
    ),
  getStateImportData: (state) =>
    axios.get(
      `${env("DAEDALUS_API_HOST")}/api/shipments/recent/to?state=${state}`,
    ),
  getCityTransporters: (city, state) => {
    const url = `${env(
      "DAEDALUS_API_HOST",
    )}/api/drivers/best?state=${state}&city=${city}`
    console.log("Calling transporters API:", url)
    return axios.get(url)
  },
  getStateTransporters: (state) =>
    axios.get(
      `${env("DAEDALUS_API_HOST")}/api/drivers/best?state=${state}&limit=6`,
    ),
  createShipment: (data, token) => {
    return axios.post(`${env("DAEDALUS_API_HOST")}/api/shipments`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  validateShipment: (data) => {
    return axios.post(
      `${env("DAEDALUS_API_HOST")}/api/validate/shipments`,
      data,
    )
  },
}

export default helpers
